import TopBar from "src/components/topbar";
import Footer from "src/components/home/footer";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";

import nav from "./nav.jpg";
import priya from "./priya.jpg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 43,
      padding: "1em",
      "& .MuiTypography-body1": {
        maxWidth: "50ch",
        fontSize: "1.2rem",
        textAlign: "justify",
        marginBottom: "1em",
        display: "inline-block",
      },
    },
    top: {
      padding: "1em",
      textAlign: "center",
      "& #origin_story_link": {
        color: theme.palette.primary.main,
      },
      "& #mission": {
        alignItems: "center",
        marginTop: "1em",
        "& .MuiGrid-item:first-child": {
          color: (theme.palette as any).orange.default,
          paddingRight: "1em",
        },
        "& .MuiGrid-item:last-child": {
          color: (theme.palette as any).indigo.default,
          paddingRight: "1em",
        },
      },
    },
    bios: {
      "& .MuiPaper-root": {
        padding: "1em",
      },
      "& .pic": {
        textAlign: "center",
        "& img": {
          height: 250,
          width: 250,
          borderRadius: "50%",
        },
      },
      "& .title": {
        alignItems: "center",
        margin: "1em",
        "& .MuiGrid-item:first-child": {
          textAlign: "right",
          paddingRight: "1em",
        },
        "& .MuiGrid-item:last-child": {
          textAlign: "center",
          "& h4": {
            color: theme.palette.primary.main,
          },
        },
      },
      "& .longform": {
        textAlign: "center",
        "& a": {
          color: theme.palette.primary.main,
          textDecoration: "none",
        },
      },
    },
  })
);

export default function About() {
  const classes = useStyles();
  return (
    <>
      <TopBar />
      <div className={classes.root}>
        <Paper elevation={0} className={classes.top}>
          <Typography variant="h2" color="primary">
            Let’s do good better.
          </Typography>
          <Typography variant="h5">
            <strong>fieldscope</strong> is born out of this simple idea.
          </Typography>
          <Typography variant="body1" align="justify" style={{ margin: "2em" }}>
            Our experience in the social impact community convinced us that the
            status-quo of social sector operations needs to change. Read more
            about our{" "}
            <a
              href="https://medium.com/fieldscope-blog/the-fieldscope-story-5b62ef9a4f73"
              rel="noopener noreferrer"
              target="_blank"
              id="origin_story_link"
              // className={classes.origin_story_link}
            >
              origin story here
            </a>
            .
          </Typography>
          <Grid container id="mission">
            <Grid item sm={4} xs={12}>
              <Typography variant="h3">Mission</Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Typography variant="h4" color="primary">
                Reimagine social-sector operations through simple and smart
                technology.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Grid container className={classes.bios} spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper elevation={3}>
              <div className="pic">
                <img src={priya} />
              </div>
              <Grid container className="title">
                <Grid item xs={4}>
                  <Typography variant="h4">CEO</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Button
                    variant="text"
                    href="https://in.linkedin.com/in/pkekre"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Typography variant="h4">Priya Kekre</Typography>
                  </Button>
                </Grid>
              </Grid>
              <div className="longform">
                <Typography variant="body1">
                  Priya has worked with the development sector for almost a
                  decade.
                </Typography>
                <Typography variant="body1">
                  Her experience includes working with a consortium of partners
                  including the University of Toronto, Emory University, CARE,
                  World Vision and Bill &amp; Melinda Gates Foundation.
                </Typography>
                <Typography variant="body1">
                  She has niche expertise in balancing the rigor of academic
                  research with the practicalities of on-the-ground programs.
                  She specialises in evaluation design, impact measurement and
                  knowledge translation. She has{" "}
                  <a
                    href="https://faseb.onlinelibrary.wiley.com/action/doSearch?ContribAuthorStored=Kekre,%20Priya"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    published and presented
                  </a>{" "}
                  internationally on her work on program evaluations set in
                  Bihar, India.
                </Typography>
                <Typography variant="body1">
                  Priya is optimistic about the role of technology, when used in
                  the right context, to catalyze positive social change at scale
                  for India. Priya holds a Master’s degree in Global Public
                  Health from Emory University, USA.
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3}>
              <div className="pic">
                <img src={nav} />
              </div>
              <Grid container className="title">
                <Grid item xs={4}>
                  <Typography variant="h4">CTO</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Button
                    variant="text"
                    target="_blank"
                    href="https://abhi.in"
                    rel="noopener noreferrer"
                  >
                    <Typography variant="h4">Nav Patel</Typography>
                  </Button>
                </Grid>
              </Grid>
              <div className="longform">
                <Typography variant="body1">
                  Nav has tinkered with computers ever since he was little — his
                  childhood curiosity has evolved into a driving ambition to
                  always build the next interesting thing.
                </Typography>
                <Typography variant="body1">
                  Nav's career has thus far been bookended with two tech giants:
                  Microsoft and Apple.
                </Typography>
                <Typography variant="body1">
                  In-between the entrepreneurial bug got the better of him.
                  Since 2010, he has co-built and successfully exited two
                  companies:{" "}
                  <a
                    target="_blank"
                    href="https://www.crunchbase.com/organization/the-shared-web"
                    rel="noopener noreferrer"
                  >
                    The Shared Web
                  </a>{" "}
                  in New York, NY and{" "}
                  <a
                    target="_blank"
                    href="https://www.crunchbase.com/organization/wifislam"
                    rel="noopener noreferrer"
                  >
                    WiFiSLAM
                  </a>{" "}
                  in Silicon Valley.
                </Typography>
                <Typography variant="body1">
                  His expertise is in building technology and turning them into
                  products. Nav is excited to apply his tech skills to social
                  enterprise in India.
                </Typography>
                <Typography variant="body1">
                  Nav holds a Computer Engineering degree from University of
                  Waterloo, Canada.
                </Typography>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
}
